import React from 'react'
import { FormattedMessage } from 'react-intl'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import LocalizedLink from '../i18n/localizedLink'
import styles from './contact.module.scss'

export default class ContactPage extends React.Component {
  state = {
    name: '',
    email: '',
    content: '',
    botField: '',
    sent: false,
    sending: false,
    error: false,
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  send = async event => {
    event.preventDefault()
    const payload = {
      name: this.state.name,
      email: this.state.email,
      content: this.state.content
    }
    const url = "/api/inquiries"

    try {
      this.setState({
        sending: true,
      })
      const response = await fetch(url, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(payload)
      })
      if (response.ok) {
        // Intentionally NOT set sending to false to prevent multiple posts.
        this.setState({
          sent: true,
          error: false,
        })
      } else {
        this.setState({
          sent: false,
          sending: false,
          error: true
       })
      }
    } catch (error) {
      this.setState({
        sent: false,
        sending: false,
        error: true,
      })
    }
  }

  render() {
    const data = this.props.data
    const contact = data.allContentfulContact.edges[0].node
    const locale = this.props.pageContext.locale
    const hrefLangs = this.props.pageContext.hrefLangs
    return (
      <Layout locale={locale}>
        <SEO
          title={contact.title}
          description={contact.description}
          hrefLangs={hrefLangs}
        />
        <main role="main" className={styles.main}>
          <section className={styles.hero}>
            <h1 className={styles.hero_heading}>
              <FormattedMessage id="contact" />
            </h1>
            <figure className={styles.hero_img}>
              <Img fluid={data.contact.childImageSharp.fluid} />
            </figure>
          </section>
          <section className={styles.contact}>
            {this.state.sent && (
              <div className={styles.thanks}>
                <p>{contact.message}</p>
                <p>
                  <LocalizedLink to="/"><FormattedMessage id="back_to_top"/></LocalizedLink>
                </p>
              </div>
            )}
            {this.state.error && (
              <p className={styles.submit}>
                <span className={styles.submit_error}>{contact.error}</span>
              </p>
            )}
            {!this.state.sent && (
              <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="botField" onSubmit={this.send}>
                <input type="hidden" name="form-name" value="contact" />
                <p style={{display: `none`}}>
                  <label>
                    <span>Dont' fill this out: <input type="botField" onChange={this.handleInputChange} /></span>
                  </label>
                </p>
                <p>
                  <label>
                    <span>{contact.name}</span>
                    <input
                      type="text"
                      name="name"
                      placeholder="Loquat"
                      value={this.state.name}
                      onChange={this.handleInputChange}
                      required
                    />
                  </label>
                </p>
                <p>
                  <label>
                    <span>{contact.mail}</span>
                    <input
                      type="email"
                      name="email"
                      placeholder="contact@loquat.md"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                      required
                    />
                  </label>
                </p>
                <p>
                  <label>
                    <span>{contact.content}</span>
                    <textarea
                      name="content"
                      rows="5"
                      value={this.state.content}
                      onChange={this.handleInputChange}
                      required
                    ></textarea>
                  </label>
                </p>
                <p className={styles.submit}>
                  <button type="submit" disabled={this.state.sending}>
                    {contact.send}
                  </button>
                </p>
              </form>
            )}
          </section>
        </main>
      </Layout>
    )
  }
}

export const query = graphql`
  query($localeLanguage: String!) {
    allContentfulContact(filter: { node_locale: { eq: $localeLanguage } }) {
      edges {
        node {
          title
          description
          name
          mail
          content
          send
          message
          error
        }
      }
    }
    contact: file(relativePath: { eq: "head.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
